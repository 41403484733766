.form__label {
  display: block;
  position: relative;
}

.form__label:last-of-type {
  margin-bottom: 15px;
}

@media (min-width: 880px) {
  .form__label:last-of-type {
    margin-bottom: 18px;
  }
}