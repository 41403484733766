.header_login {
  display: grid;
}

.header_login::before {
  content: "";
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--color-gray-70);
  display: block;
  transition: all 0.5s ease-out;
  opacity: 0;
}

@media (min-width: 880px) {
  .header_login {
    display: flex;
    margin-top: 0;
    padding-top: 45px;
  }

  .header_login::before {
    display: none;
  }
}

.header_active.header_login::before {
  opacity: 1;
}