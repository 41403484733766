.popup__button-close {
  position: absolute;
  width: 20px;
  height: 20px;
  top: -36px;
  right: 0;
  background: url('../../../images/icon-close.svg') center / cover no-repeat;
  border: none;
}

@media (min-width: 880px) {
  .popup__button-close {
    width: 32px;
    height: 32px;
    top: -40px;
    right: -40px;
  }
}
