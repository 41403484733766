.profile__name {
  margin: 0;

  font-weight: 500;
  font-size: 27px;
  line-height: 33px;
  text-align: center;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (min-width: 880px) {
  .profile__name {
    font-size: 42px;
    line-height: 48px;
  }
}