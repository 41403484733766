.header__wrapper {
  opacity: 0;
  top: -142px;
  left: 50%;
  display: flex;
  gap: 18px;
  padding: 40px 0 41px;
  flex-direction: column;
  align-items: center;
  transition: all 0.5s;
  position: absolute;
  grid-column: 1/-1;
  transform: translateX(-50%);
  transition: all 0.5s ease-out;
}

@media (min-width: 880px) {
  .header__wrapper {
    display: flex;
    position: static;
    flex-direction: row;
    justify-content: flex-end;
    gap: 24px;
    padding: 0;
    transform: translateX(0);
    opacity: 1;
  }
}

.header_active .header__wrapper {
  opacity: 1;
}