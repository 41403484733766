.auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 260px;
  margin: 0 auto;
}

@media (min-width: 880px) {
  .auth {
    width: 358px;
  }
}