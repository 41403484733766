.gallery__photo {
  display: block;
  object-fit: cover;
  aspect-ratio: 1/1;
  cursor: pointer;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: none;
  width: 100%;
}

.gallery__photo:hover {
  opacity: 0.6;
}