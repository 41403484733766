.header__button-toggle_active .header__button-lines {
  background-color: transparent;
}

.header__button-toggle_active .header__button-lines::after {
  top: 0;
  transform: translateX(-50%) rotate(-45deg);
}

.header__button-toggle_active .header__button-lines::before {
  top: 0;
  transform: translateX(-50%) rotate(45deg);
}
