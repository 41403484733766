.header__logo {
  width: 104px;
  display: block;
}

@media (min-width: 880px) {
  .header__logo {
    width: 142px;
  }
}
