.profile__job {
  width: 100%;
  margin: 0;
  text-align: center;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (min-width: 880px) {
  .profile__job {
    font-size: 18px;
    line-height: 22px;
    text-align: start;
  }
}