:root {
  /* Цвета */
  --color-main: #000;
  --color-main-20: rgba(0, 0, 0, 0.2);
  --color-main-50: rgba(0, 0, 0, 0.5);
  --color-main-80: rgba(0, 0, 0, 0.8);
  --color-main-90: rgba(0, 0, 0, 0.9);
  --color-second: #fff;
  --color-gray: #545454;
  --color-gray-70: rgba(84, 84, 84, 0.7);
  --color-error: #FF0000;
  --color-placeholder: #c4c4c4;
  --color-placeholder-sing: #ccc;
  --color-light-gray: #A9A9A9;
}
