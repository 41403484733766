.form__button-save_type_auth {
  padding: 14px 0 13px;
  margin-top: 143px;

  font-size: 16px;
  line-height: 19px;
  color: var(--color-main);

  border: none;
  border-radius: 2px;
  background-color: var(--color-second);
  width: 100%;
}

.form__button-save_type_auth:not([disabled]):hover {
  opacity: 0.85;
}

.form__button-save_type_auth:disabled {
  border: none;
  background-color: var(--color-second);
  color: var(--color-main);
  cursor: default;
}

@media (min-width: 880px) {
  .form__button-save_type_auth {
    font-size: 18px;
    line-height: 22px;
    padding: 14px 0;
    margin-top: 186px;
  }
}