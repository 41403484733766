.popup {
  display: flex;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--color-main-50);
  justify-content: center;
  align-items: center;
  z-index: 5;
}