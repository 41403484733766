.auth__title {
  width: 100%;
  margin: 40px 0;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

@media (min-width: 880px) {
  .auth__title {
    margin: 60px 0 50px;
    font-size: 24px;
    line-height: 29px;
  }
}