.profile__button-avatar {
  border: none;
  position: relative;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
  background-color: transparent;
  flex-shrink: 0;
  margin-bottom: 26px;
}

.profile__button-avatar::before {
  opacity: 0;
  content: "";
  position: absolute;
  border: none;
  top: 0;
  left: 0;
  width: 120px;
  aspect-ratio: 1/1;
  background: var(--color-main-80) url("../../../images/icon-edit.svg") center/26px 26px no-repeat;
  transition: 1s opacity ease-out;
  border-radius: 50%;
}

.profile__button-avatar:hover::before{
  opacity: 1;
}