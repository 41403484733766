.error__subtitle {
  margin: 0 0 50px;
  font-weight: 900;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  max-width: 280px;
}

@media (min-width: 880px) {
  .error__subtitle {
    max-width: 400px;
    font-size: 30px;
    line-height: 34px;
  }
}