.popup__heading {
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  color: var(--color-main);
  margin: 0 0 75px;
}

@media (min-width: 880px) {
  .popup__heading {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 48px;
  }
}
